import { createStore } from "vuex";

export default createStore({
  state: {
    reporterState: {},
    casinoList: [],
    errorList: []
  },
  getters: {
    reporterState(state) {
      return state.reporterState;
    },
    casinoList(state) {
      return state.casinoList;
    },
    errorList(state) {
      return state.errorList;
    }
  },
  mutations: {
    updateReporterState(state, payload) {
      state.reporterState = payload;
    },
    updateCasinoList(state, payload) {
      state.casinoList = payload;
    },
    updateErrorList(state, payload) {
      state.errorList = payload;
    }
  },
  actions: {
    setReporterState(state, payload) {
      this.commit('updateReporterState', payload);
    },
    setCasinoList(state, payload) {
      this.commit('updateCasinoList', payload);
    },
    setErrorList(state, payload) {
      this.commit('updateErrorList', payload);
    }
  },
  modules: {},
});
