<template>
	<footer>
		<h3>{{ casinoGroupName }}</h3>
		<div class="versions" v-if="!isMobile">
			<span>Server Version: {{ serverVersion.serverVersion }}</span>
			<span>Operator Apps Version: {{ operatorAppsVersion }}</span>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	props: {
		serverVersion: Object,
		isMobile: Boolean,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #32373f;
}

.versions {
	display: flex;
	flex-flow: column nowrap;
	position: absolute;
	top: 5px;
	left: 0;
	margin: 5px 15px;
}

.versions span {
	user-select: all;
}
</style>
