<template>
	<div class="home gradient-background">
		<h1 class="outline-black">Reports Home</h1>
		<div v-if="reporterState?.isLoggedOn">
			<h1 class="outline-black">Logged in as {{ reporterState?.displayName }}</h1>
			<h2 class="outline-black">Phone: {{ reporterState?.username }}</h2>
			<div class="permissions-lists-container">
				<table class="permissions">
					<tr class="header-row">
						<th colspan="2">{{ reporterState?.displayName }} Permissions</th>
					</tr>
					<tr>
						<th>Cashier</th>
						<td :class="reporterState?.isCashier.toString()">{{ reporterState?.isCashier }}</td>
					</tr>
					<tr>
						<th>Reporter</th>
						<td :class="reporterState?.isReporter.toString()">{{ reporterState?.isReporter }}</td>
					</tr>
					<tr>
						<th>SiteAdmin</th>
						<td :class="reporterState?.isSiteAdmin.toString()">{{ reporterState?.isSiteAdmin }}</td>
					</tr>
					<tr>
						<th>Sysadmin</th>
						<td :class="reporterState?.isSysadmin.toString()">{{ reporterState?.isSysadmin }}</td>
					</tr>
					<tr>
						<th>HelpDesk</th>
						<td :class="reporterState?.isHelpDesk.toString()">{{ reporterState?.isHelpDesk }}</td>
					</tr>
				</table>
				<div class="limitations-container">
					<p v-if="reporterState?.permissionsSuspended">
						Permissions for this account have been temporarily suspended. Please contact your administrator to have them restored.
					</p>
					<p v-if="!(reporterState?.isReporter || reporterState?.isSysadmin) && reporterState?.isLoggedOn && devMode">
						Your permissions do not allow access to Unified Sales Report.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	name: "Home",
	props: {
		reporterState: Object,
		isMobile: Boolean,
	},
};
</script>

<style scoped>
.home {
	color: #fff;
	height: calc(100vh - 150px);
	width: 85%;
	margin-left: 15%;
	margin-top: 0;
}

.permissions {
	position: relative;
	width: auto;
	margin: auto;
	border-collapse: collapse;
	text-align: center;
	color: #000;
	background-color: #d7d7d7;
	box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%);
}

.permissions * {
	padding: 5px 10px;
}

.permissions-lists-container {
	height: calc(100vh - 22em);
	overflow: hidden auto;
}

.permissions * {
	width: 50%;
	border: 1px #000 solid;
}

.permissions tr {
	transition: background-color 0.3s;
}

tr.header-row {
	border-bottom: 1px solid #000;
}

.permissions tr.data-row:hover,
.permissions tr:nth-child(2n):not(.open):hover {
	background-color: #fff;
	color: #000;
}

.permissions tr:nth-child(2n):not(.open) {
	background-color: #bbb;
}

.true {
	background-color: #080;
	color: #fff;
}

.false {
	background-color: #646464;
	color: #969696;
}

.limitations-container p {
	width: 60%;
	padding: 15px 30px;
	margin: auto;
	background-color: rgb(67 66 80 / 50%);
	backdrop-filter: blur(3px);
	color: #ff0;
	text-align: center;
	font-weight: bold;
}

.limitations-container p:first-child {
	margin-top: 15px;
}

.limitations-container p + p {
	padding-top: 0 !important;
}
</style>
