<template>
	<div class="dropdown" v-if="casinoList">
		<input
			class="dropdown-input"
			:name="name"
			@focus="showOptions()"
			@blur="exit()"
			@keyup="keyWatcher($event)"
			v-model="searchFilter"
			:disabled="disabled"
			:placeholder="placeholder"
		/>
		<div class="dropdown-content" v-show="optionsShown">
			<div v-if="filteredOptions.length > 0">
				<div class="dropdown-item" v-for="(casino, index) in filteredOptions" :key="index" @mousedown="selectOption(casino)">
					{{ casino.name }}
				</div>
			</div>
			<div v-else class="no-matches">
				No matches
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DropdownMenu",
	props: {
		casinoList: Array,
		defaultCasinoId: String,
	},
	data() {
		return {
			selected: {},
			searchFilter: "",
			filteredOptions: [],
			name: "",
			placeholder: "Type to filter casino list",
			disabled: false,
			optionsShown: false,
		};
	},
	watch: {
		searchFilter() {
			this.filterOptions();
		},
	},
	mounted() {
		this.filterOptions();
		this.selected = this.casinoList.filter((casino) => casino.id === this.defaultCasinoId)[0] || {};
		this.selectOption(this.selected);
	},
	methods: {
		selectOption(casino) {
			this.selected = casino;
			this.optionsShown = false;
			this.searchFilter = this.selected.name;
			this.eventBus.emit("casinoSelected", this.selected);
		},
		showOptions() {
			if (!this.disabled) {
				this.searchFilter = "";
				this.optionsShown = true;
			}
		},
		exit() {
			if (!this.selected.id) {
				this.selected = {};
				this.searchFilter = "";
			} else {
				this.searchFilter = this.selected.name;
			}
			this.eventBus.emit("casinoSelected", this.selected);
			this.optionsShown = false;
		},
		keyWatcher(e) {
			if (e.key === "Enter" && this.filteredOptions[0]) this.selectOption(this.filteredOptions[0]);
		},
		filterOptions() {
			this.filteredOptions = [];
			if (this.searchFilter?.length === 0) {
				this.filteredOptions = this.casinoList;
				return;
			}

			let regOption = new RegExp(this.searchFilter, "ig");
			for (const casino of this.casinoList) {
				if (casino.name.match(regOption)) this.filteredOptions.push(casino);
			}
			return;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dropdown {
	position: relative;
	display: block;
	margin: auto;
}

.dropdown-input {
	background: #fff;
	cursor: pointer;
	border: 1px solid #e7ecf5;
	/* border-radius: 3px; */
	color: #333;
	display: block;
	/* padding: 6px; */
	min-width: 250px;
}

.dropdown-input:hover {
	background: #f8f8fa;
}

.dropdown-content {
	position: absolute;
	background-color: #fff;
	/* min-width: 248px;
	max-width: 248px; */
	width: 100%;
	max-height: 248px;
	border: 1px solid #e7ecf5;
	box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
	overflow: auto;
	z-index: 1;
}

.dropdown-item, 
.no-matches {
	color: black;
	line-height: 1em;
	padding: 8px;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

.dropdown-item {
	cursor: pointer;
}

.no-matches {
	cursor: not-allowed;
}

.dropdown-item:hover {
	background-color: #5a8fef;
}

.dropdown:hover .dropdowncontent {
	display: block;
}
</style>
